import React from 'react'
import './header.css'

const header = () => {
  return (
    <header>
        <h1>AI Image Negator</h1>
        <h3>Enter a prompt and the generator will show you an image of the opppsite!</h3>
    </header>
  )

}

export default header